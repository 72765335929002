


















import axios, { AxiosResponse } from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class Maintenance extends Vue {

  metaInfo() {
    return  {
      title: `Service in maintenance`
    }
  }

  mounted() {
    this.$self_render.navbarEnable = false;
  }
  goToBack() {
    this.$self_render.navbarEnable = true;
    this.$router.push({ path: "/" });
  }
  created() {
    this.registerEvent();
  }
  registerEvent() {
    setTimeout(() => this.checkHealth(), 15000);
  }
  async safe_get(url: string): Promise<AxiosResponse<any, any>> {
      try {
        return await axios.get(url);
      }
      catch {
        return { 
              status: 521,
              statusText: "network error"
        } as any;
      }
  }
  async checkHealth() {
    let result = await this.safe_get("/health");
    if (result.status != 200) {
      this.registerEvent();
      console.log(`health check failed: `, result);
    } 
    else this.goToBack();
  }
}
